import Layout from "../components/layout"
import Hero from "../components/hero"
import React, { useState } from "react"
import Reviews from "../components/reviews"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import * as PricingStyles from "../styles/pricing.module.scss"

const PricingPage = () => {
  const data = useStaticQuery(graphql`
    query PricingQuery {
      paintBrushImage2: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/brush-painting-minified-sizereduce" }) {
        secure_url
      }
      ipadEstimatePDF: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/pricing-ipad" }
      ) {
        secure_url
      }
      checkMark: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/tick_2" }) {
        secure_url
      }
    }
  `)

  const [leadInfo, setLeadInfo] = useState({
    name: "",
    email: "",
  })
  const [formInfo, setFormInfo] = useState({
    formSubmitted: false,
  })

  const handleInputChange = e => {
    const key = e.target.name
    const value = e.target.value
    setLeadInfo({
      ...leadInfo,
      [key]: value,
    })
  }

  const submitForm = e => {
    e.preventDefault()
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-10865682082/yugPCKGB5KgDEKLNlL0o",
      })
    }

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead", { currency: "USD" })
      }
    }

    fetch(`${process.env.GATSBY_ZONUM_API_URL}/pricing-guide`, {
      method: "POST",
      body: JSON.stringify({
        ...leadInfo,
        status: "Open",
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ARN:
          " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/POST/pricing-guide",
        Auth: "NONE",
      },
    }).then(() => setFormInfo({ formSubmitted: true }))
  }

  return (
    <Layout>
      <SEO
        title="Pricing"
        description="Pivot Painters Chicago Painting Service"
      />
      <Hero name="Pricing Guide" src={data.paintBrushImage2.secure_url} />
      <div className={PricingStyles.pricingContainer}>
        <div className={PricingStyles.pricingSubContainer}>
          {formInfo.formSubmitted === true ? (
            <div
              className={PricingStyles.pricingGuideSubmissionMessageContainer}
            >
              <p className={PricingStyles.pricingGuideSubmissionMessage}>
                Your Copy of Pivot Painters's Pricing Guide Has Been Sent To
                Your Email!
              </p>
            </div>
          ) : (
            <div className={PricingStyles.pricingForm}>
              <p>Make Your Estimate Today!</p>

              <form onSubmit={e => submitForm(e)}>
                <div className={PricingStyles.pricingInputContainer}>
                  <label>First Name</label>
                  <input
                    name="name"
                    onChange={handleInputChange}
                    value={leadInfo.name}
                    type="text"
                  />
                </div>
                <div className={PricingStyles.pricingInputContainer}>
                  <label>Email</label>
                  <input
                    name="email"
                    onChange={handleInputChange}
                    value={leadInfo.email}
                    type="text"
                  />
                </div>
                <input
                  className={PricingStyles.pricingGuideButton}
                  type="submit"
                  name="submit"
                  value="Get Your FREE Pricing Guide"
                />
              </form>
            </div>
          )}

          <div className={PricingStyles.contentContainer}>
            <div className={PricingStyles.iPadImageContainer}>
              <img
                src={data.ipadEstimatePDF.secure_url}
                alt="ipad with photo of pivot painters estimating pdf"
              />
            </div>

            <div className={PricingStyles.incentives}>
              <h4 className={PricingStyles.contentTitle}>
                Here's What You Get:
              </h4>
              <div className={PricingStyles.incentive}>
                <div>
                  <img src={data.checkMark.secure_url} alt="check mark" />
                </div>

                <p>Easy To Follow Instructions</p>
              </div>
              <div className={PricingStyles.incentive}>
                <div>
                  <img src={data.checkMark.secure_url} alt="check mark" />
                </div>

                <p>Do Your Own Estimate At Your Convenience</p>
              </div>
              <div className={PricingStyles.incentive}>
                <div>
                  <img src={data.checkMark.secure_url} alt="check mark" />
                </div>

                <p>Learn How Much Your Project Will Cost</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage
